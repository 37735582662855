import { application } from './controllers/application';
import QnaController from './controllers/qna_controller';
application.register('qna', QnaController);

// Lazy loading videos
// https://web.dev/lazy-loading-video/
document.addEventListener('DOMContentLoaded', function () {
  const lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

  if ('IntersectionObserver' in window) {
    const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          video.target.src = video.target.dataset.src;
          video.target.load();
          video.target.classList.remove('lazy');
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
});
