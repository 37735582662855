import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['plusButton', 'minusButton', 'answer'];

  expand() {
    this.plusButtonTarget.classList.add('hidden');
    this.minusButtonTarget.classList.remove('hidden');
    this.answerTarget.classList.remove('hidden');
  }

  collapse() {
    this.plusButtonTarget.classList.remove('hidden');
    this.minusButtonTarget.classList.add('hidden');
    this.answerTarget.classList.add('hidden');
  }
}
